<template>
  <div class="msp-allenamento-detail mx-auto msp-fixed-container" >
    <template v-if="allenamento && allenamento.id">
      <v-app-bar class="msp-fixed-header" dark  :color=" 'var(--col-sport-'+allenamento.sport_id+')'"
      >
      <v-btn icon @click="dismissModal()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title>
        <i v-if="!isRiposo" class="ev-all__icon " :class="'msp-icon-sport-'+allenamento.sport_id" alt=""></i>

        {{formatDate(allenamento.data)}}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <template v-if="canEdit">
        <v-btn icon @click="deleteItem()">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn icon @click="openModal()">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <msp-confirm ref="confirmDelete"></msp-confirm>
      </template>
      </v-app-bar>



        <v-container fluid class="msp-fixed-content">
          <v-sheet>
            
                <h2  class="titolo">{{allenamento.titolo}}</h2>

            <v-tabs-items v-model="tab">
              <v-tab-item
              :key="1"
              >

              <div v-if="hasGraficoAndature(allenamento)" class="grafico-andature">
              <msp-grafico-andature
              :dettagli = "allenamento.dettagli"
              :zone-attive = "zoneAttive"
              :sport_id = "allenamento.sport_id"
              :colore = "color"
              ></msp-grafico-andature>
            </div>
<div v-if="hasComputing(allenamento.sport_id)" class="allenamento-dati mb-3">

                <span class="dati-item">

                  <span class="dati-label">
                    <span class="msp-icon-time" aria-hidden="true"></span>
                  </span>
                  <span class="dati-value">  {{allenamento.totalone && secondsToHms(allenamento.totalone.sec)}}</span>
                </span>


                <span class="dati-item"
                v-if="allenamento.totalone && allenamento.totalone.mt">

                <span class="dati-label">
                  <span class="msp-icon-distance" aria-hidden="true"></span>
                </span>
                <span class="dati-value">{{renderDistanza(allenamento)}}</span>

              </span>

              <span class="dati-item" v-if="allenamento.trimp">

                <span class="dati-label">trimp: </span>
                <span class="dati-value">{{formatTrimp(allenamento.trimp)}}</span>
              </span>
            </div>
            <div class="select-detail-mode mb-2">

                <v-chip small color="#fff" v-if="hasZone(allenamento.sport_id)" class="switch-mode">
                    <v-icon>mdi-text</v-icon>

                    <v-switch
                        v-model="detailAutoMode"
                        @change="saveAutoMode"
                    ></v-switch>
                    <v-icon>mdi-chart-histogram</v-icon>
                </v-chip>

                <v-chip small color="#fff" v-if="isPalestra(allenamento.sport_id)" class="switch-mode">
                    <v-icon>mdi-arrow-collapse-vertical</v-icon>

                    <v-switch
                        v-model="expandedMode"
                    ></v-switch>
                    <v-icon>mdi-arrow-expand-vertical</v-icon>
                </v-chip>
            </div>

              <component :is="detailComponent"
              :allenamento="allenamento"
              :tests="tests"
              :class="{'auto': detailAutoMode, 'collapsed': !expandedMode}"
              ></component>

              
            <div v-if="allenamento.note" class="note-allenatore pre">
              {{allenamento.note  }}
            </div>
            <div v-if="allenamento.url_youtube">
              <msp-videos :single="true" :can-edit="false" v-model="allenamento.url_youtube" @update="update()"></msp-videos>

            </div>
          </v-tab-item>
          <v-tab-item
          :key="2"
          >
          <v-dialog
          scrollable
          v-model="isAssociazione"

          >
          <v-card class="aggiungi-attivita">
            <v-toolbar
            :color=" 'var(--col-sport-'+allenamento.sport_id+')'"
            dark
            >

            <v-toolbar-title>Attività</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
            dark
            icon
            @click="toggleAssociazione"
            >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>

          <lista-attivita
          :data = "allenamento.data"
          :sport_id = "allenamento.sport_id"
          :calendario_id = "allenamento.calendario_id"
          :attivita_id = "allenamento.attivita_id"
          @select = "selectAttivita"
          >

        </lista-attivita>
      </v-card-text>
      </v-card>
      </v-dialog>

      <div v-if="canAssocia" class="assocazione-buttons">
        <v-btn  @click="toggleAssociazione" color="primary">
        <v-icon class="mr-2">mdi-link</v-icon>Associa attività</v-btn>
      </div>
      <v-card
      class="mb-3 mt-3"
      elevation = 5
      v-if="allenamento.attivita"
      :color="'var(--col-sport-'+allenamento.sport_id+')'"
      dark
      >
      <v-card-subtitle class="pb-0"><strong>{{formatTime(allenamento.attivita.dataAllenamento)}}</strong></v-card-subtitle>
      <v-card-title

      class="headline justify-space-between">
      {{allenamento.attivita.titoloAllenamento || allenamento.attivita.sport}}
      <v-chip
      class="chip-percentuale"
      outlined
      label
      >
      {{allenamento.attivita.percRaggiungimento}} %
      </v-chip>
      </v-card-title>

      <v-card-subtitle>{{allenamento.attivita.sport}}</v-card-subtitle>

      <v-card-actions>
        <v-btn  @click="toggleAssociazione" color="primary">Cambia attività</v-btn>
        <v-btn  @click="selectAttivita(null)" color="error">
        <v-icon>mdi-link-off</v-icon></v-btn>
        <v-spacer></v-spacer>
        <v-btn  @click="openAttivita" >
        <i class="msp-icon-graph"> </i></v-btn>
      </v-card-actions>
      </v-card>
      <div v-if="hasFeedback(allenamento.sport_id)" class="allenamento-feedback-container">
        <allenamento-feedback

        :allenamento="allenamento"
        @update-feedback="updateFeedback"

        ></allenamento-feedback>
      </div>
      </v-tab-item>
      <v-tab-item
      :key="3"
      >

      <div class="test-attivi">
        <div class="test-attivi__container">
          <div class="test-attivo" v-for="test in tests" :key="'test-'+test.id">
            <test-dettaglio
            :descrizione="false"
            :test="test">
          </test-dettaglio>
          <zone mode="view" :sport_id="test.sport_id" :um_id="test.um_id" :um_descrizione="test.um_descrizione"  :value="test.zone" debug="zone attive"></zone>
        </div>

      </div>
      </div>
      </v-tab-item>
      <v-tab-item
      :key="4"
      >
      <msp-workout :allenamento="allenamento"></msp-workout>
      </v-tab-item>


      </v-tabs-items>
      </v-sheet>
      </v-container>


      
<div class="msp-fixed-footer"
      scroll-target="#app"
      >
      <!-- -->
      <v-tabs
      v-model="tab"
      centered
      :fixed-tabs="true"
      icons-and-text
      dark  :background-color=" 'var(--col-sport-'+allenamento.sport_id+')'"
      >
      <v-tabs-slider color="yellow"></v-tabs-slider>

      <v-tab
      v-for="item in filteredTabs"
      :key="'tab-'+item.id"
      >
      <span class="small">{{ item.text }}</span>
      <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
      </v-tabs>

      </div>
    </template>
  </div>
</template>



<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import canEdit from "@mixins/canEdit.js";
import mspVideos from '@components/mspVideos.vue'
import _api from "@mixins/api.js";
import testDettaglio from '@components/testDettaglio.vue'
import zone from '@src/js/vue/include/test/Zone.vue'
import allenamentoFeedback from '@components/allenamentoFeedback.vue'
import dettaglioPodismo from '@components/dettaglioPodismo.vue'
import dettaglioCiclismo from '@components/dettaglioCiclismo.vue'
import dettaglioNuoto from '@components/dettaglioNuoto.vue'
import dettaglioAlimentazione from '@components/dettaglioAlimentazione.vue'
import dettaglioCustom from '@components/dettaglioCustom.vue'
import dettaglioPalestra from '@components/dettaglioPalestra.vue'
import env from "@mixins/environment.js"
import listaAttivita from '@components/listaAttivita.vue'
import mspGraficoAndature from '@components/MspGraficoAndature.vue'
import mspWorkout from '@components/MspWorkout.vue'
import mspConfirm from '@components/MspConfirm.vue'
import {mergeDeep} from "@mixins/merge.js";
import { mapGetters } from 'vuex'
import { URLparams } from "@src/js/msp/api.js";

const ROOT_API = process.env.VUE_APP_API_BASE_URL + '/api/v2';
let loadAllenamento = function (id) {

  let url = ROOT_API+"/calendario.php?mobile&id="+id;

  return new Promise((resolve,reject)=>{
      _api(url, "GET")
    .then((result)=>{

        if (result.success) {
          resolve(result);
        } else {
          resolve(null);
        }
    })
    .catch((error)=>{
        reject(error);
      });
  });
  // this.$emit("update", this.allenamento);
}
const component = {
  components: {
    testDettaglio,
    mspVideos,
    zone,
    allenamentoFeedback,
    dettaglioPodismo,
    dettaglioCiclismo,
    dettaglioNuoto,
    dettaglioAlimentazione,
    dettaglioCustom,
    dettaglioPalestra,
    listaAttivita,
    mspGraficoAndature,
    mspWorkout,
    mspConfirm,
  },
  mixins: [
    env,
    allenamentoCalcoli,
    canEdit,
  ],
  name: 'AllenamentoDettaglio',
  props: [
  ],
  watch: {
        allenamento: function (no) {
            if (!(no&&no.id)) {
                this.dismissModal();
            }
            this.updateAllenamento();
        }
  },
  beforeRouteEnter (to, from, next) {
    let id = to.params.id;
    if (!from.name) {
      next('calendario');
    } else {
      loadAllenamento(id)
      .then((result)=>{
        next((vm)=>{
          let allenamento = result.data.data;
          vm.allenamento = allenamento;
          vm.$store.dispatch("calendario/updateEvent", {id: id, event: {data: allenamento}});
          vm.loadTests();
          vm.checkCanEdit(id);
        })
      });
    }
  },
  computed: {
    ...mapGetters('atleti', [
      'atleta',
    ]),
    ...mapGetters('utente', [
      'utente',
    ]),
    filteredTabs: function ()
    {
      let tabs = [...this.tabs];
      if (!this.hasZone(this.allenamento.sport_id))
      {
        let tabZone = [...tabs].findIndex((el)=>(el.id===3));
        tabs.splice(tabZone,1);
      }
      if (!this.hasFeedback(this.allenamento.sport_id))
      {
        let tabFeedback = [...tabs].findIndex((el)=>(el.id===2));
        tabs.splice(tabFeedback,1);
      }
      if (!(
      this.utente
      && this.utente.is_atleta
      && this.canExport(this.allenamento.sport_id)
      )) {
        let tabFeedback = [...tabs].findIndex((el)=>(el.id===4));
        tabs.splice(tabFeedback,1);
      }
      return tabs;
    },
    canAssocia() {
      return this.utente
      && this.utente.is_atleta
      && !this.allenamento.attivita
    },

    detailComponent() {
      return "dettaglio-"+this.allenamento.sport_codice;
    },
    isRiposo() {
      if (!this.allenamento) {
        return false;
      }
      return this.allenamento.sport_id === this.MSP().ID_RIPOSO
    },

  },
  data() {
    return {
      tab: null,
      allenamento: null,
      zoneAttive: [],
      isAssociazione: false,
      detailAutoMode: false,
      expandedMode: true,
      color: true,
      tests: [],
      tabs: [
        { id: 1,
          text: 'allenamento',
          icon: 'mdi-calendar-blank',
        },
        { id: 2,
          text: 'feedback',
          icon: 'mdi-note-text',
        },
        { id: 3,
          text: 'zone',
          icon: 'mdi-heart',
        },
        { id: 4,
          text: 'esporta',
          icon: 'mdi-upload',
        },
      ],
      content: 'Content',
    }
  },
  mounted() {

    const storeAutoMode = localStorage.getItem("autoMode");
      if (storeAutoMode == 'true') {
          this.detailAutoMode = true;
      } else {
          this.detailAutoMode = false;
      }
  },

  methods: {
      saveAutoMode: function () {
          localStorage.setItem('autoMode', this.detailAutoMode);
      },
    async deleteItem() {
      if (
        await this.$refs.confirmDelete.open(
          "Elimina",
          "Sei sicuro?"
        )
      ) {
        this.deleteConfirm();
        this.dismissModal();
      }
    },
    deleteConfirm() {
      let id = this.allenamento.id;
      this.$store.dispatch("calendario/deleteEvent", id);
    },
    openAttivita: function () {
        let attivita_id =  this.allenamento.attivita_id;
      this.$router.push("/calendario/attivita/view/"+attivita_id);
    },
    openModal: function () {
      let item = this.allenamento;
      this.$router.push("/calendario/allenamento/edit/"+item.id);
    },
    formatTime: function (string) {
      if (!string)
      {
        return "";
      }
      if (!this.isValidDate(string))
      {
        return string;
      }
      return new Date(string).toLocaleString("it");

    },

    toggleAssociazione: function () {
      this.isAssociazione = !this.isAssociazione
    },

    selectAttivita: function (attivita) {
      this.isAssociazione = false;

      if (!attivita) {
/* Se non è selezionata un'attività, 
 * dissocia quella dell'allenamento e la aggiunge alle attività libere  */

        let currentAttivita = {
          ...this.allenamento.attivita,
          data: this.allenamento.attivita.dataAllenamento.substr(0,10),
        };
        this.dissociaAttivita(this.allenamento.calendario_id);
        let data = {
          attivita_id: null,
          attivita: null,
          percRaggiungimento: null,
        }
        this.allenamento = mergeDeep(this.allenamento, data);
        this.$store.dispatch("calendario/addAttivita", {attivita:[currentAttivita]});
        this.$store.dispatch("calendario/updateEvent", {id: this.allenamento.calendario_id, event: {data: data}});
      } else {
/* Se è selezionata un'attività, 
 * la associa all'allenamento e la toglie dalle attività libere */

          this.associaAttivita({calendario_id:this.allenamento.calendario_id,attivita_id:attivita.id})
          .then(
            ()=>{

              this.loadAttivita(attivita.id)
              .then(
                (result)=>{
                  if (result.success)
                  {
                    let data = {
                      attivita_id: attivita.id,
                      attivita: result.data,
                      percRaggiungimento: result.data.percRaggiungimento,
                    }
                    this.allenamento = mergeDeep(this.allenamento, data);
                    this.$store.dispatch("calendario/updateEvent", {id: this.allenamento.calendario_id, event: {data: data}});
                    this.$store.dispatch("calendario/removeAttivita", {id: attivita.id});

                  }
                }
              )

            }
          )
      }
    },

    dismissModal () {
      this.$store.dispatch("calendario/setDialog", false);
      this.$router.go(-1);
    },

    updateAllenamento: async function () {
          await this.setZoneAttive();
    },

    loadRisultato: async function (allenamento) {
        const params = {
            id: allenamento.risultato_id,
        };
        const p = URLparams(params);
        const url = ROOT_API+"/risultato.php?"+p;
        const result = await _api(url, "GET");
        if (result.success) {
            return (result.data);
        } else {
            return (null);
        }
    },
    clearZoneAttive: function () {
        this.zoneAttive = null;
    },

    loadRisultatoDefault: async function (sport_id) {
        let url = ROOT_API + `/risultato.php?default&sport_id=${sport_id}`;

        const result = await _api(url, 'GET');
        if (result.success) {
            return result.data;
        } else {
            return null;
        }
    },

    setZoneAttive: async function () {
        const risultato = await this.loadRisultato(this.allenamento);
        let zoneAttive;
        if (risultato) {
            zoneAttive = risultato.zone;
        } else {
            this.clearZoneAttive();
            const risultatoDefault = await this.loadRisultatoDefault(this.allenamento.sport_id);
            if (risultatoDefault) {
                zoneAttive = risultatoDefault.zone;
            }
        }

        this.zoneAttive = zoneAttive;


    },

    updateFeedback: function (feedback) {
        let data = {
            feedback_secondi: feedback.secondi,
            feedback_metri: feedback.metri,
            feedback_rpe: feedback.rpe,
            feedback_note: feedback.note,
            feedback_valutazione: feedback.valutazione,
        }
        this.$store.dispatch("calendario/updateEvent", {id: this.allenamento.calendario_id, event: {data: data}});

    },

    loadTests: function () {
      this.tests = [];
      if (!this.atleta) return;
      let oggi = this.MSP().formatDateYmd(new Date());


      let url = ROOT_API + `/risultato.php?attivi&dettaglio=1&sport_ids=${this.allenamento.sport_id}&data=${oggi}&atleta_id=${this.atleta.id}`;

      _api(url, 'GET')
      .then( (result)=>{
          if(result.success) {
            this.tests = result.data;
          }
        });


    },

    loadAttivita: function (id) {
        let url = ROOT_API + '/calendario.php?attivita='+id;
        return new Promise((resolve, reject)=>{
            _api(url, "GET")
                .then((result)=>{
                    resolve(result);
                })
                .catch((error)=>{
                    reject(error);
                });

        });

    },

    associaAttivita: function ({calendario_id, attivita_id}) {
        let url = ROOT_API + '/attivita.php?associa&id='+attivita_id;
        let data = {
            calendario_id: calendario_id,
        };
        return new Promise((resolve, reject)=>{

            _api(url, "PUT", data)
                .then((result)=>{
                    resolve(result);
                })
                .catch((error) =>{
                    reject(error);
                });

        });
    },

    dissociaAttivita: function (calendario_id) {
        let url = ROOT_API + '/attivita.php?dissocia&calendario_id='+calendario_id;
        return new Promise((resolve, reject)=>{

            _api(url, "PUT")
                .then((result)=>{
                    resolve(result);
                })
                .catch((error) =>{
                    reject(error);
                });
        });

    },
  }
}
export default component;
</script>
<style lang="scss">
.msp-allenamento-detail
{
    .select-detail-mode {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

  .v-tab {
    padding: 0;
    min-width: 45px;
    .small {
      letter-spacing: 0;
      text-transform: none;
      font-size: 9px;
    }
  }
  .grafico-andature {
    margin: 5px 0;
    padding: 5px 5px 0 5px;
    display: flex;
    height: 50px  ;
    .msp-grafico-andature {
      max-width: 500px;

    }

  }

  .allenamento-dettaglio {
    .msp-andature-dettaglio {
      display: none;
    }
    .note-attivita {
      display: block;
    }
    &.collapsed {
      .allenamento-palestra-col2,
      .allenamento-palestra-col3 {
        display: none !important;
      }
    }
    &.auto {
      .msp-andature-dettaglio {
        display: block;
      }
      .note-attivita {
        display: none !important;
      }

    }
  }
  .allenamento-dati {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    gap: 2px;
    flex-wrap: wrap;
    .dati-item {
      display: flex;
      text-align: center;
      flex: 1;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
    }
    .dati-value {
      font-weight: bold;

    }
  }
  .allenamento-feedback-container {
    overflow-x: auto;
    width: 100%;
    position: relative;
  }
  .note-allenatore {
    padding: 5px;
    border: 2px solid var(--col-ui-evidenza);
    border-radius: 5px;
    margin: 5px 0;
    background: var(--col-ui-evidenza-tint);
  }

  .v-app-bar {
    .v-toolbar__title {
      display: flex;
      align-items: center;
      line-height: 2em;
      padding: 0 !important;
      [class*="msp-icon-sport-"] {
        font-size: 2em;
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  h2 {
      font-size: 1.2em;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }

  .small {
    font-size: 10px;
  }
  .v-avatar {
    i {
      font-size: 80px;
    }

  }

  .scheda-toolbar {
    background-color: var(--color) !important;
    color: #fff;
  }

  .test-attivi {
    .zone {
      overflow-x: auto;
    }
  }
  .switch-mode {
    margin: 0;
  }
  .chip-percentuale {
      border:none;
      padding: 0;
    font-size: 30px;
    font-weight: bold;
  }


}

</style>
