<template>
    <div class="msp-grafico-andature">
        <div v-for="(item, i) in items" class="andatura" :class="'andatura-'+item.zona_id" 
            :key="i" :style="getStyle(item)">
            <div class="andatura-bar"></div>
            <div class="andatura-tooltip" v-html="renderZona(item)"></div>
        </div>
    </div>
</template>

<script>
import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import zone from "@cached/zone.json";
import distanze from "@lib/distanze.js";
import {methods as methodsAndaturaZonaRange} from '@src/js/vue/mixins/andaturaZonaRange.js'
import _msp_constants from '@src/js/msp/constants.js';
import _funzioni from '@src/js/msp/funzioni.js';

const component = {
  props: ['dettagli','zone-attive', 'sport_id', 'colore', 'tests', 'espandi'],
  mixins: [
    allenamentoCalcoli,
  ],
  data: function () {
    return {
      maxHeight: zone.reduce((acc,el)=>(Math.max(acc,+el.posizione)),1),
    }
  },
  computed: {
    zone: function () {
        return zone.filter(el=>el.sport_id===this.sport_id);
    },

    hasTests: function () {
        return (this.filtered_tests && this.filtered_tests.length);
    },

    filtered_tests: function () {
      if (!this.espandi) { return []; }
      if (!(this.tests && this.tests.length)) { return []; }
      return this.tests.filter((el)=>(el.sport_id===this.sport_id));
    },

    items: function () {
        const items = [];
        if (!this.dettagli) {
            return items;
        }
        this.dettagli.map((d)=>{
            if (!d.serie) { return; }
            const rd = +d.ripetute || 1;
            for (let id=0; id < rd; id++){
                d.serie.map((s)=>{
                    if (!s.andature) {
                        return;
                    }
                    const rs = +s.ripetute || 1;
                    for (let is=0; is < rs; is++){
                        s.andature.map((andatura)=>{

                            let zona = this.getZona(andatura.andatura_zona_id);
                            if (!zona) return null;
                            let range;
                            let da = +zona.posizione;
                            let a = da + 1;
                            if (this.hasCustomRange(andatura)) {

                                let percentuale = ((+andatura.andatura_zona_range_da)+(+andatura.andatura_zona_range_a))/2;
                                range = da + (a-da)*percentuale/100;
                            } else {
                                range = da;
                            }
                            const y = range / (this.maxHeight);
                            let x;
                            if (!this.zoneAttive && (!_funzioni.isDistance(andatura.um_id))) {
                                let conv_um = _msp_constants.UMS.sec;
                                x = _funzioni.um2um(andatura.val_andatura, andatura.um_id, conv_um);
                            } else if (this.zoneAttive) {

                                x = _funzioni.convertiMtSecAndatura(andatura, this.zoneAttive).sec;
                            } else {
                                x = 0;
                            }
                            const item = {
                                x: x,
                                y: y,
                                zona_id: andatura.andatura_zona_id,
                                andatura: andatura,
                            };
                            items.push(item);
                        }).filter(el=>el);
                    }
                })
            }
        });
        return items;
    },

  },
  methods: {
    ...methodsAndaturaZonaRange,

    minRipetute: function (dettaglio) {
        let ripetute = +dettaglio.ripetute;
        if (!ripetute) return 1;
        return ripetute;
    },

    

    renderZona: function (item) {
        const sep = "<br>";
        if (!this.espandi) { return ""; }
        let zonaRange;
        const zona_id = item.zona_id;
        if (item.andatura) {
            zonaRange = this.zonaRangeDescrizione(item.andatura);
        } else {
            zonaRange = "";
        }


        let zone = [];
        if (!this.hasTests || !item.andatura) {
            let zona = this.zone.find(el=>el.id == zona_id);
            if (!zona) return "";
            return zona.codice + zonaRange;
        }

        const andatura = item.andatura;
        this.filtered_tests.forEach((test)=>{
            let zona = this.makeZonaForRange(test,zona_id);
            if (zona) {
                zone.push(zona);
            }
        });
        if (!zone.length) { return ""; }

        let results = [];
        const codice = this.zone.find(el=>el.id==zona_id).codice;

        results.push(`<strong class='codice-zona'>${codice} ${zonaRange}</strong>`);
        zone.forEach((zona_obj)=>{
            let params = [zona_obj.um_id, zona_obj.um_descrizione];
            if (this.sport_id != _msp_constants.ID_NUOTO) {
                results.push(this.formatDescrizioneZona(zona_obj, andatura)+sep);
            } else {

                if (this.zoneAttive) {
                    distanze.forEach((distanza)=>{
                        let zona = this.zoneAttive.find((el)=>{
                            let found =  (+el.id == +andatura.andatura_zona_id) && (el.distanza == distanza.id);
                            return found;
                        });
                        let value;
                        if (zona) {
                            value = _funzioni.formatRisultatoByUmId((zona.da+zona.a)/2, ...params) 
                        } else {
                            value = "/";
                        }

                        results.push(`${distanza.name} : ${value} ${sep}` );
                    });
                }
            }
        });
        return results.join("");
    },

    /** Cerco la zona attiva in base al codice.
     * Nel nuoto, prendo quella su distanza 100mt. */
    getZonaAttiva: function (id) {
        let filtered;
        if (_msp_constants.ID_NUOTO == this.sport_id) {
            filtered = this.zoneAttive.find(el=>{
                let found = (+el.id == +id) && (el.distanza == "100");
                return found;
            });
        } else {
            filtered = this.zoneAttive.find(el=>+el.id===+id);
        }
        return filtered;
    },

    getZona: function (id) {
        let filtered = this.zone.find(el=>+el.id===+id);
        return filtered;
    },


    getStyle: function (item) {
      let a_style=[];
      if (!item.zona_id) { return ""; }
      const width = item.x;
      const height = item.y;
      const zona = zone.find(el=>el.id === item.zona_id);
      a_style.push(`--width:${width}`);
      a_style.push(`--height:${height}`);
      if (this.colore) {
        const color = zona.colore;
        a_style.push(`--color:${color}`);
      }
      return a_style.join(";");
    },


    formatAndatura: function (andatura) {
      let result = "";
      if (andatura.val_andatura) {
        result += " " + andatura.val_andatura;
      }
      if (andatura.des_zona) {
        result += " " + "in " + andatura.des_zona;
      }
      return result;
    }
  }
}
export default component;
</script>

<style lang="scss">
.msp-grafico-andature {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding: 3px 3px 0 3px;
  .dati-item {
    display: flex;
    flex: 1;
    white-space: nowrap;
    padding: 0 10px;
    align-items: center;
    background: var(--col-grigio-chiarissimo);
    gap: 3px;
    border-radius: 5px;
  }
  .dati-value {
    font-weight: normal;

  }
  .andatura {
    --height: 1;
    --width: 10;
    --scale-y: 100%;
    --scale-x: 1%;
    --color: #ccc;
    --color-hover: rgba(255,255,255,0.2);
    position: relative;
    width: calc(var(--scale-x) * var(--width));
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding:0px;
    &:hover {
        background: var(--color-hover);
    }
  }
  .andatura-bar {
      position: relative;
    background: var(--color);
      height: calc(var(--scale-y) * var(--height));
      width: 100%;
      position: relative;
  }

  .andatura-tooltip {
    position: absolute;
    display: none;
  }
  .andatura:hover .andatura-tooltip {
    display: block;
    left: 0;
    bottom: 100%;
    z-index: 100;
    padding: 10px;
    text-align: left;
    background: var(--col-grigio-scuro);
    color: #fff;
    border-radius: 5px;
    margin-bottom: 0px;
    white-space: nowrap;
    & .codice-zona {
      display: block;
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;
    }
  }

  .dettaglio,
  .serie,
  .ripetuta,
  .andatura {
    display: flex;
    align-items: flex-end;
  }

}

</style>
